<template>
    <div
        class="flex absolute bottom-0 gap-4 justify-center left-0 right-0"
        :class="{'position-absolute' : absolute}">
        <a
            v-for="item of footerLinks"
            :key="item.link"
            :href="item.link"
            target="_blank"
            class="flex items-center mb-3 gap-1">
            <p class="m-0 text-gray-700 dark:text-white">{{ item.name }}</p>
        </a>
    </div>
</template>

<script setup>
const props = defineProps({
    absolute: {
        type: Boolean,
        default: true
    }
})

const footerLinks = [
    {
        name: "Impressum",
        link: "/impressum"
    }
]
</script>

<style scoped>
.text-footer {
  font-size: 0.9rem;
}
</style>